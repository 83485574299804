* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: beige; */
}

body {
  font-family: -apple-system, B linkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#deb887;
}

footer {
  background-color: #343a40;
  color: #fff;
}

.card a{
  color:blue
}
      
.img-checked {
  padding: 0px;
  width: 500px;
  background: wheat;
  color: wheat;
  border-radius: 10px;
  font-weight: bolder;
  position: relative;
  /* margin-top: 2px ; */
}
.img-card{
  border: 10px;
  margin: 10px;
  width: 300px;
}
.img-card:hover {
  border: 1px solid white;
}
