.footer {
  background-color: black;
  color: whitesmoke;
  padding:0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-top: 5px;
  padding: 2px;
}

.footer h5 {
  color: whitesmoke;
  margin-left: 20px;
}

.footer-section {
  margin-bottom:0px;
}

.footer-section p {
  margin: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.footer-section p svg {
  margin-right: 10px;
}

.social-media-icons a {
  margin-left: 10px;
  color:whitesmoke;
  margin-right:5px;
  font-size: 1.5rem;
}

.social-media-icons a:hover {
  color: blue;
}

a:link {
  color: whitesmoke;
  text-decoration: none;
}
