/* ProfileCard.css */

.profile-card {
    margin: 1rem 0;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    border-radius: 10px;
  }
  
  .profile-card:hover {
    transform: translateY(-5px);
  }
  
  .profile-card-img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .profile-card-body {
    text-align: center;
  }
  
  .profile-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .profile-card-subtitle {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 1rem;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .social-icon {
    color: #0077b5; /* LinkedIn blue */
    margin: 0 0.5rem;
    transition: color 0.2s;
  }
  
  .social-icon:hover {
    color: #0056b3; /* Darker LinkedIn blue */
  }
  