/* Custom CSS for Navbar Component */
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

/* General navbar styling */
.navbar {
  background-color:wheat  /* Light background */
}

/* Make the logo and brand name align correctly */
.navbar-brand { 
  display: flex;
  align-items: center;
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: normal
 
}
/* Style for the logo image */
.navbar-brand .logo {
  margin-right: 10px;
  border-radius: 10px;
}

/* Adjust the gap between navbar items */
.navbar-nav .nav-link, .navbar-nav .nav-dropdown {
  margin-right: 5px;

}

/* Hoverable dropdown */
.hoverable-dropdown .dropdown-menu {
  display: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.hoverable-dropdown:hover .dropdown-menu {
  display: block;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .navbar-nav .nav-link, .navbar-nav .nav-dropdown {
    margin-right: 5px;
  }
}

@media (max-width: 768px) {
  .navbar-nav {
    width: 100%;
    justify-content: space-around;
  }

  .navbar-nav .nav-link, .navbar-nav .nav-dropdown {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
/* Style for Navbar brand */
.navbar-brand {
  color: black !important; /* White color for brand text */
}

/* Style for Navbar links */
.nav-link {
  color: black !important; /* White color for links */
}

.nav-link:hover {
  color: black !important; /* Light grey color on hover */;
}

/* Style for Navbar dropdown */
.navbar .dropdown-menu {
  background-color: wheat; /* Dark background color for dropdown */
  border: none;
}

.navbar .dropdown-item {
  color: #000 !important; /* White color for dropdown items */
}

.navbar .dropdown-item:hover {
  background-color: #212121; /* Slightly lighter dark color on hover */
  color: white!important;
}
